import React, { Component, createContext } from 'react';

import { auth } from '../firebase';
import { createUserProfileDocument } from '../utils/firebaseUtil';

export const UserContext = createContext();

class UserProvider extends Component {
  state = { user: null };

  unsubscribeFromAuth = null;

  componentDidMount = async () => {
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);

        userRef.onSnapshot(snapshot => {
          this.setState({
            user: {
              uid: snapshot.id,
              ...snapshot.data(),
            },
          });
        });

        this.setState({ user: userAuth });
      } else {
        this.setState({ user: null });
      }
    });
  };

  componentWillUnmount = () => {
    this.unsubscribeFromAuth();
  };

  render() {
    const { user } = this.state;
    const { children } = this.props;

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
  }
}

export default UserProvider;
